<template>
   <div :class="this.classes">
      <slot></slot>
   </div>
</template>

<script scoped>
export default {
   name: 'OrizontalScrollView',
   props: {
      classname: {
         type: String,
         default: ''
      }
   },
   data() {
      return {
         classes : 'orizontal-scroll-view d-flex flex-row flex-nowrap overflow-scroll mb-4 py-3 ' + this.classname
      }
   }
};
</script>

<style scoped>
.orizontal-scroll-view::-webkit-scrollbar {
    display: none;
}
</style>