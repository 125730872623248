<template>
  <div class="shop-screen content-view">

    <OrizontalScrollView classname="justify-content-center" v-if="rerender">
      <CategoryButton v-for="cat in product_categories" :key="cat.id" :id="cat.id" :label="cat.name" :current="product_category.id" @clicked="onClickProductCategory(cat);forceRerender();" />
    </OrizontalScrollView>

    <!-- content  -->
    <div class="container px-0">
      <div class="category-content radius-20 px-3 pt-4 mb-5 shadow">
      
        <div class="" v-html="strings['shop_cat_'+product_category.slug+'_desc']"></div>

      </div>
    </div>

    <div id="products-list" class="row justify-content-center" v-if="products.length">
      <div class="col-md-4 mb-4" v-for="product in products" :key="product.id">
        <ProductCard :product="product" v-if="! categorie_card_corso.includes(product_category.slug)" />
      </div>
    </div>

    <div class="text-center">
      <div class="spinner-border" role="status" v-if="! products.length">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductCard from "@/components/ProductCard";
import OrizontalScrollView from "@/components/OrizontalScrollView";
import CategoryButton from "@/components/CategoryButton";

export default {
  data(){
    return {
      rerender : true,
      render_card: false,
      categorie_card_corso: ['corsi','lezioni','bambini']
    }
  },

  computed: {
    ...mapGetters("product", ["products", "product_category","product_categories"]),
    ...mapGetters("account", ["strings"])
  },
  
  components: { 
    ProductCard, 
    OrizontalScrollView, 
    CategoryButton 
  },
  
  methods: {

    ...mapActions("product", ["getProducts", "addCart", "removeCart","getProductCategories", "onClickProductCategory"]),

    forceRerender() {
      // Remove my-component from the DOM
      this.rerender = false;
      
      this.$nextTick(() => {
        // Add the component back in
        this.rerender = true;
      });
    }
  
  },
  
  created() {
    if( ! this.product_categories.length ){
      this.getProductCategories();
    }
  }
};
</script>

<style>
.category-content {
    background: #02a69d;
    color: #fff;
}
.detailbox{
  background: #fff;
  padding: 15px;
  border-radius: 20px;
  color: #54595f;
  height:100%;
}
.detailbox h3{
  color: #00756d;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.detailbox ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.detailbox li{
  position: relative;
  padding-left: 20px;
}
.detailbox li:before{
  content: "\f058";
  color: #f5a018;
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
}
</style>