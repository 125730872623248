import { render, staticRenderFns } from "./OrizontalScrollView.vue?vue&type=template&id=80b9d718&scoped=true&"
import script from "./OrizontalScrollView.vue?vue&type=script&scoped=true&lang=js&"
export * from "./OrizontalScrollView.vue?vue&type=script&scoped=true&lang=js&"
import style0 from "./OrizontalScrollView.vue?vue&type=style&index=0&id=80b9d718&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80b9d718",
  null
  
)

export default component.exports