<template>
  <router-link 
   class="product-card card shadow mb-4"
   :to="'/product/' + product.id">
      
      <img :src="product.images[0].src" class="card-img-top product-image" />
      
      <h3 class="card-title">{{ product.name }}</h3>
      
      <div class="addtocart-button">
         <i class="fab fa-opencart"></i>
      </div>
  </router-link>
</template>
<script scoped>
export default {
    name: "ProductCard",
    props: ["product"]
};
</script>

<style scoped>
.product-card{
   position: relative;
   border-radius: 20px;
   overflow: hidden;
}
.product-card:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(0deg, rgba(0,0,0,0.7) 15%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(
0deg
, rgba(0,0,0,0.7) 15%, rgba(0,0,0,0) 100%);
    background: linear-gradient(
0deg
, rgba(0,0,0,0.7) 15%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}
.product-image {
   height: 300px;
   object-fit: cover;
}
.card-title {
   position: absolute;
   bottom: 0;
   margin: 15px;
   max-width: calc(100% - 85px);
   width: 100%;
   text-align: left;
   color: #fff;
}
.addtocart-button {
   position: absolute;
   right: 0;
   margin: 15px;
   bottom: 0;
   background: #02a69d;
   color: #fff;
   width: 40px;
   height: 40px;
   line-height: 42px;
   border-radius: 50%;
   text-align: center;
}
</style>