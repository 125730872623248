<template id="categoryButton">
   
   <div v-if="id" :class="classes" :id="id" @click="clicked">
      
      {{ label }}
      
   </div>
   
</template>

<script>
import Vue from 'vue';
import { Utils } from '@/inc/Utils';

export default Vue.component('CategoryButton', {
   template: '#categoryButton',
   mixins : [ Utils ],
   props: {
      id: {
         default : 0,
         type: Number,
         required: true
      },
      label: {
         default: 'Label',
         type: String,
         required: true
      },
      current: {
         default : 0,
         type: Number,
         required: true
      }
   },
   data() {
      return {
         classes: 'category-button' + (this.current == this.id  ? ' current' : '')
      }
   },
   methods: {
      clicked() {
         this.$emit('clicked');
         this.classes = 'category-button' + (this.current == this.id  ? ' current' : '');
      }
   }
});
</script>

<style scoped>
.category-button {
   display: inline-block;
   padding: 10px 15px;
   border-radius: 20px;
   cursor: pointer;
   font-size: 15px;
   font-weight: 600;
}
.category-button.current, .category-button:hover{
   background: #02a69d;
   color: #fff;
   box-shadow: 0px 4px 10px #eaeaea;
}
.category-button:not(:first-child) {
   margin-left: 20px;
}
</style>